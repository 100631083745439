import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/style/main.css";
import Antd from "ant-design-vue";

//  动画
import "animate.css";
import WOW from "wow.js";

new WOW({
  boxClass: "wow", // 类名，在用户滚动时显示隐藏的框。
  animateClass: "animate__animated", // 触发CSS动画的类名称（animate.css库默认为“ animated”）
  offset: 0, // 定义浏览器视口底部与隐藏框顶部之间的距离。当用户滚动并到达此距离时，隐藏的框会显示出来。
  mobile: true, // 在移动设备上打开/关闭WOW.js。
  live: true, // 在页面上同时检查新的WOW元素。
}).init();
createApp(App).use(Antd).use(store).use(router).mount("#app");
