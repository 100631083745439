import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/concat",
    name: "Concat",
    component: () => import("../views/Concat.vue"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "新闻中心",
    },
  },
  {
    path: "/Product",
    name: "Product",
    component: () => import("../views/Product.vue"),
    meta: {
      title: "产品介绍",
    },
  },
  {
    path: "/checkin",
    name: "CheckIn",
    component: () => import("../views/CheckIn.vue"),
    meta: {
      title: "代理商申请",
    },
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
    meta: {
      title: "新闻中心",
    },
  },
  {
    path: "/news/detail",
    name: "NewsDetail",
    component: () => import("../views/NewsArticle.vue"),
    meta: {
      title: "新闻详情",
    },
  },
  //   404
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: "404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
//  跳转时候设置 title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - 华夏北斗卫星 ";
  }
  next();
});

export default router;
