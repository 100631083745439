import { createStore } from "vuex";
import router from "../router/index";

export default createStore({
  state: {
    loadingState: false,
    companyInfo: {},
    CopyWriting: [],
  },
  getters: {
    getCompanyInfoByLink(state) {
      const link = router.currentRoute.value.fullPath;
      const CopyWritingInfo = state.CopyWriting.find((item) => item.link === link);
      return CopyWritingInfo || {};
    },
    getCompanyInfo(state) {
      return state.companyInfo;
    },
    getLoadingState(state) {
      return state.loadingState;
    },
  },
  mutations: {
    setCopyWritingInfo(state, payload) {
      state.CopyWriting = payload;
    },
    setCompanyInfo(state, payload) {
      state.companyInfo = payload;
    },
    setLoadingState(state, payload) {
      state.loadingState = payload;
    },
  },
  actions: {},
  modules: {},
});
