<template>
  <a-config-provider
    :locale="locale"
    :theme="{
      token: {
        colorPrimary: '#3DBDE1',
      },
    }"
  >
    <a-spin :spinning="loadingState" size="large" style="min-height: 100vh">
      <router-view />
    </a-spin>
  </a-config-provider>
</template>
<script setup>
import { computed, ref } from "vue";
import { Spin } from "ant-design-vue";
import { useStore } from "vuex";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
const store = useStore();
const locale = ref(zhCN);

const loadingState = computed(() => store.getters.getLoadingState);
</script>

<style lang="scss">
html,
body {
  background: #f7f7f7;
}
</style>
